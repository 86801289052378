import React from 'react';
import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Projects from '../components/Projects';
import About from '../components/About';
import Interests from '../components/Interests';
import { Background, Parallax } from 'react-parallax';
import BGFM from '../assets/images/foggy-lake.jpg';

const IndexPage = () => (
  <Layout>    
    <Header />
    <Parallax strength={600}>
    <Background>
        <img src={BGFM} /> 
        {/* https://www.npmjs.com/package/react-parallax      */}
    </Background> 
    <header className="masthead2">
    <br></br>
    <br></br>  
      <div className="container d-flex h-75 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto mt-5 mb-0">{config.heading}</h1>
          <hr></hr>      {/* underline main heading*/}
          <h2 className="text-white-50 mx-auto mt-3 mb-0">
            {config.subHeading}
            <br></br>
            <br></br> 
          </h2>
          <Scroll type="id" element="about">              
          <a className="arrow-container" href="#">
            <div className="arrow"></div>
            <div className="arrow"></div>
            <div className="arrow"></div> 
          </a>
          </Scroll>          
        </div>        
      </div>
    </header>
    </Parallax>
    

    <About />  

    <Interests />

    {/* <Projects /> */}

    {/* <Subscribe /> */}

    <SocialLinks />

    <Footer />

  </Layout>
);

export default IndexPage;
