import React from 'react';
import Scroll from '../components/Scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCoffee,
  faTools,
  faFlagCheckered,
  faCode,
  //faDog,
} from '@fortawesome/free-solid-svg-icons'
import SocialLinks from '../components/SocialLinks';

export default function Interests() {
    return (
    <section id="interests" className="interests-section text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <h2 className="text-white mb-4">In my free time I enjoy...</h2>
            <p className="text-white-50">
            <br></br>
            <div className="cards1">
              <div className="card1">
              <FontAwesomeIcon icon={faCode} size="3x" color="#fff" />
              <div>
              <h4>Problem Solving</h4> 
              </div>
            </div>
            <div className="card1">
              <FontAwesomeIcon icon={faTools} size="3x" color="#fff" />
              <div>
              <h4>Working on Motorcycles</h4> 
              </div>
            </div>
            <div className="card1">
              <FontAwesomeIcon icon={faFlagCheckered} size="3x" color="#fff" />
              <div>
              <h4>Watching Formula 1</h4> 
              </div>
            </div>
            <div className="card1">
              <FontAwesomeIcon icon={faCoffee} size="3x" color="#fff" />
              <div>
              <h4>Sipping coffee</h4> 
              </div>
            </div>
            {/* <div className="card1"><FontAwesomeIcon icon={faDog} size="4x" color="#fff" /></div> */}
            </div>
            </p>
          <Scroll type="id" element="socialLinks">              
              <a className="arrow-container3" href="#">
                  <div className="arrow3"></div>
                  <div className="arrow3"></div>
                  <div className="arrow3"></div> 
              </a>
          </Scroll>
        </div>
      </div>
    </div>
    {/* <SocialLinks /> */}
    </section> 
    
    );

}
