
import React from 'react';
import ADF2 from '../assets/images/ADF-200.png';
import AF2  from '../assets/images/AF-200.png';
import Scroll from '../components/Scroll';

export default function About() {
    return (
    <section id="about" className="about-section text-center">
      <div className="container ">
      <br></br> 
              <br></br> 
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">Software Product and Cloud Engineer.</h2>
               <p className="text-white-50">
               Microsoft Azure certified, Senior Product Engineer specializing in operations and product delivery with a focus on client, development and services support.   
               <br></br> 
               <br></br> 
               Subject Matter Expert in proprietary talent management SaaS application in additon to IaaS, PaaS and SaaS Azure services.   
              <br></br> 
              <br></br> 
              <br></br> 
              <a href="https://www.credly.com/badges/8b2af743-3e3c-4bd0-b09f-f1c330189541/public_url" target="_blank"> 
              <img className="img-fluid" src={AF2} alt="" />
              </a>             
              <a href="https://www.credly.com/badges/718d1351-2800-4e7a-b63f-41df4c19eadd/public_url" target="_blank"> 
              <img className="img-fluid" src={ADF2} alt="" />
              <br></br> 
              <br></br> 
              <br></br> 
              <br></br> 
              </a>
              </p>          
            <Scroll type="id" element="interests">              
                <a className="arrow-container2" href="#">
                    <div className="arrow2"></div>
                    <div className="arrow2"></div>
                    <div className="arrow2"></div> 
                </a>
            </Scroll>
          </div>
        </div>
      </div>
    </section> 
    );
}