import React from 'react';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMap,
  faMobile,
} from '@fortawesome/free-solid-svg-icons'


export default function SocialLinks() {
  return (
    <section id="socialLinks" className="contact-section bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="text-primary mb-2"><FontAwesomeIcon icon={faMap} size="1x"/></i>
                <h4 className="text-uppercase m-0">Location</h4>
                <hr className="my-4" />
                <div className="small text-black-50">{config.address}</div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="text-primary mb-2"><FontAwesomeIcon icon={faEnvelope} size="1x"/></i>
                <h4 className="text-uppercase m-0">Email</h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  <a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="text-primary mb-2"><FontAwesomeIcon icon={faMobile} size="1x"/></i>
                <h4 className="text-uppercase m-0">Phone</h4>
                <hr className="my-4" />
                <div className="small text-black-50">{config.phone}</div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="social d-flex justify-content-center">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a key={url} href={url} className={`mx-2`}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div> */}
      </div>
    </section>
  );
}
